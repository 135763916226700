









import { Component, Vue, Mixins } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import DrillTransition from '@/mixins/drillsv2/DrillTransition'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class ContinueLearn extends Mixins(DrillTransition) {
  private subjectCode = this.$route.params.subjectCode

  get nameClassObject(): { [key: string]: boolean } {
    return {
      'continue__learn__unit-information__name__sugaku': this.subjectCode === 'su',
      'continue__learn__unit-information__name__kokugo': this.subjectCode === 'ko',
      'continue__learn__unit-information__name__eigo': this.subjectCode === 'ei',
      'continue__learn__unit-information__name__rika': this.subjectCode === 'ri',
      'continue__learn__unit-information__name__syakai': this.subjectCode === 'sh',
    }
  }

  private isUnitLearning = false

  private unitData: { resultDrillId: number; unit: string } | null = null
  private async checkIsUnitLearning(): Promise<void> {
    const response = await Vue.prototype.$http.httpWithToken(
      'v2/drills/drill_incomplete?subjectCode=' + this.subjectCode
    )
    this.unitData = response.data.drill
  }

  private async continueLearn() {
    if (!this.unitData) return
    Vue.prototype.$loading.start()
    const resultDrillId = this.unitData.resultDrillId
    const response = await Vue.prototype.$http.httpWithToken.post('v2/drills/start_incomplete_drill', {
      drill_id: resultDrillId,
    })
    Vue.prototype.$loading.complete()
    this.$router.push({
      path: this.urlDrillWithId(resultDrillId, response.data.currentPage),
    })
  }

  private mounted() {
    this.checkIsUnitLearning()
  }
}
